/**
 * 将十六进制字符串转换为 ArrayBuffer
 * @param str: 十六进制字符串
 * @returns ArrayBuffer
 */
const hex2ArrayBuffer = (str: string): ArrayBuffer => {
  const typedArray = new Uint8Array(
    (str.match(/[\da-f]{2}/gi) ?? []).map((h: string): number => parseInt(h, 16))
  );
  return typedArray.buffer;
};

/**
 * 解压缩wspush消息
 * @param text 十六进制字符串
 * @returns {} 解压缩后的对象
 */
const uncompress = (text: string) => {
  try {
    const SnappyJS = require("snappyjs");
    const CBOR = require("cbor-js");
    const buf1 = hex2ArrayBuffer(text.substring(2, text.length - 1));
    const r = SnappyJS.uncompress(buf1);
    return CBOR.decode(r);
  } catch (err) {
    return {};
  }
};
export { uncompress };
