import { apikeyBugsnagBeta } from "../../constant/base";
import type { IEnvConfig } from "@aspen/model";

export const adgmProdConfig: IEnvConfig = {
  env: "prod",
  host: {
    // dev: "http://8.219.137.127:19090/",
    dev: "https://api-ad.aspendigital.co/",
    prod: "https://api-ad.aspendigital.co/"
  },
  klineChartUrl: "",
  tvChartUrl: "https://tradingview-ad.aspendigital.co/",
  websocket: {
    host: "wss://ws-ad.aspendigital.co:8080"
  },
  // todo 暂时先用beta的key
  apiKeyBugsnag: apikeyBugsnagBeta,
  webDomain: "https://portal-ad.aspendigital.co",
  appOnlyDomain: "",
  domainUriPrefix: "",
  fallbackUrl: {
    android: "",
    ios: ""
  },
  packageName: {
    android: "",
    ios: ""
  },
  minimumVersion: {
    android: "",
    ios: ""
  },
  operationServer: "https://api-aoperation-ad.aspendigital.co",
  saasServer: "",
  appScheme: ""
};
